import {
  Box,
  Flex,
  Icon,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { Select } from '../Common/Brand';

import { IconProps, IconLocation } from './BrandIcons';
import { LocationClientAPI } from '../../db/types-client-api';
import { ChangeEvent } from 'react';
import { PhoneIcon } from '@chakra-ui/icons';

interface Props {
  regionId?: number;
  value: Partial<LocationClientAPI>;
  locations: LocationClientAPI[];
  onChange: (
    value: Partial<LocationClientAPI>,
    field: 'region' | 'building'
  ) => void;
}

export const useBuildingSelect = ({
  regionId,
  value,
  locations,
  onChange,
}: Props) => {
  const regions: { [key: number]: string } = {};
  const locationList: LocationClientAPI[] = [...(locations || [])];

  locationList.forEach(
    (location: LocationClientAPI) =>
      (regions[location.regionId] = location.region)
  );

  const regionIds: number[] = Object.keys(regions) as unknown as number[];
  const regionsOptions = regionIds.map((regionId: number) => ({
    value: regionId,
    text: regions[regionId],
  }));

  const regionName = regionId ? regions[regionId] : null;

  const buildingsOptions = locationList
    .filter((location: LocationClientAPI) => location.regionId === regionId)
    .map((locations: LocationClientAPI) => ({
      value: locations.id,
      text: locations.name,
      ...locations,
    }));

  const setRegionId = (e: ChangeEvent<HTMLSelectElement>) => {
    const id = e.target.value;
    const location: LocationClientAPI | undefined = locations.find(
      (l: LocationClientAPI) => l.regionId === Number(id)
    );

    if (location) {
      onChange(
        {
          ...value,
          regionId: location.regionId,
          region: location.region,
        },
        'region'
      );
    } else {
      onChange({}, 'region');
    }
  };

  const setRegionId2 = (e: { id: number; name: string }) => {
    const id = Number(e?.id);
    const location: LocationClientAPI | undefined = locations.find(
      (l: LocationClientAPI) => l.regionId === Number(id)
    );

    if (location) {
      onChange(
        {
          regionId: location.regionId,
          region: location.region,
        },
        'region'
      );
    } else {
      onChange({}, 'region');
    }
  };

  const setLocation = (e: ChangeEvent<HTMLSelectElement>) => {
    const id = Number(e.target.value);
    const location = locations.find((l: LocationClientAPI) => l.id === id);
    onChange(
      location || {
        regionId: value?.regionId,
        region: value?.region,
      },
      'building'
    );
  };

  return {
    regionsOptions,
    buildingsOptions,
    setRegionId,
    setRegionId2,
    setLocation,
    regionName,
  };
};

const BuildingSelect = ({ regionId, value, locations, onChange }: Props) => {
  const limitRegionId = value?.regionId || regionId;
  const { regionsOptions, setRegionId } = useBuildingSelect({
    value,
    locations,
    onChange,
    regionId: limitRegionId,
  });

  return (
    <Select
      pl={0}
      value={value?.regionId}
      onChange={setRegionId}
      options={[{ text: 'Choose Your Drop Off', value: 0 }, ...regionsOptions]}
      rounded={100}
    ></Select>
  );
};

export default BuildingSelect;

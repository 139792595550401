import React from 'react';
import { Box, SelectProps as ChakraSelectProps } from '@chakra-ui/react';

interface SelectOptions {
  value: string | number;
  text: string;
}

interface SelectProps extends ChakraSelectProps {
  options: SelectOptions[];
  onChange: (e: any) => void;
}

const SelectField = ({ options, onChange, ...props }: SelectProps) => {
  return (
    <select
      onChange={onChange}
      placeholder="Choose Your Campus"
      className="w-full relative px-[15px] py-2.5 bg-white rounded-[5px] border border-[#d0d5dd] justify-start h-[52px] items-center gap-2.5 flex outline-none hover:outline-none"
    >
      {options.map(({ value, text }) => (
        <option
          key={value}
          value={value}
          style={{ backgroundColor: '#FFF8F5' }}
        >
          {text}
        </option>
      ))}
    </select>
  );
};

export default SelectField;

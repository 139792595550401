import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import BGImage from 'public/assets/images/auth-bg.png';
import PayIcon from 'public/assets/images/Payments.png';
import ShipIcon from 'public/assets/images/Shippings.png';
import ShopIcon from 'public/assets/images/Shopping.png';
import LetGoImage from 'public/assets/images/letsgo.png';
import SelectField from '../../components/Common/SelectField';
import { useBuildingSelect } from 'components/Common/BuildingSelect';
import { LocationClientAPI } from 'db/types-client-api';
import { useDBContext } from 'context/dbContext';
import { Select } from 'components/Common/Brand';
import { LocationDispatchContext, useLocation } from 'context/LocationContext';
import Cookies from 'js-cookie';
import { fakeData } from 'server/fake-data';
import _ from 'lodash';

const Login = () => {
  const [step, setStep] = useState(1);

  const RenderChild = () => {
    switch (step) {
      case 1:
        return <HowItWork setStep={setStep} />;
      case 2:
        return <Location setStep={setStep} />;
      case 3:
        return <Campus />;
      default:
        return <HowItWork setStep={setStep} />;
    }
  };

  return (
    <div
      className="relative flex flex-col min-h-screen pt-[150px] md:pt-[84px] px-6 bg-cover"
      style={{
        backgroundImage: `url(${BGImage.src})`,
      }}
    >
      <div className="w-full h-full flex justify-center items-center py-16 mx-auto">
        <div className="max-w-[600px] w-full h-auto shadow overflow-y-auto p-5 md:px-16 md:py-12 bg-white rounded-[20px] flex-col justify-between items-center flex">
          <RenderChild />
        </div>
      </div>
    </div>
  );
};

export default Login;

const HowItWork = ({ setStep }: any) => {
  return (
    <div>
      <h1 className="text-center text-[#331103] text-[40px] md:text-[45px] font-bold leading-[45px] pb-[45px]">
        How It Works
      </h1>

      <div className="w-full flex-col justify-start items-start gap-8 flex">
        <div className="justify-start items-center gap-[15px] flex">
          <img src={ShopIcon.src} alt="..." className="w-[50px] h-[50px]" />
          <p className="text-[#66554e] text-base font-normal leading-normal">
            We partner with small, local businesses to get special deals just
            for your building.
          </p>
        </div>

        <div className="justify-start items-center gap-[15px] flex">
          <img src={ShipIcon.src} alt="..." className="w-[50px] h-[50px]" />
          <p className="text-[#66554e] text-base font-normal leading-normal">
            They deliver to your building themselves, cutting out middlemen
            services like DoorDash, Postmates, etc.
          </p>
        </div>

        <div className="justify-start items-center gap-[15px] flex">
          <img src={PayIcon.src} alt="..." className="w-[50px] h-[50px]" />
          <p className="text-[#66554e] text-base font-normal leading-normal">
            They pass their savings from not paying commissions/fees for these
            services, directly to you.
          </p>
        </div>
      </div>

      <button
        className="w-full h-[44px] mx-auto px-4 bg-[#f75413] rounded-[5px] shadow justify-center items-center flex mt-6"
        onClick={() => {
          setStep(2);
        }}
      >
        <p className="text-center text-[#fff7f4] text-xl font-bold leading-tight">
          Got It!
        </p>
      </button>
    </div>
  );
};

// const LetsGo = ({ setStep }: any) => {
//   return (
//     <div>
//       <h1 className="text-center text-[#331103] text-[40px] md:text-[45px] font-bold leading-[45px] pb-6">
//         Get $15 off your first order on us!
//       </h1>

//       <div className="w-full">
//         <img
//           src={LetGoImage.src}
//           alt="..."
//           className="w-full h-[290px] object-contain"
//         />
//       </div>

//       <button
//         className="w-full h-[44px] mx-auto px-4 bg-[#f75413] rounded-[5px] shadow justify-center items-center flex mt-6"
//         onClick={() => setStep(3)}
//       >
//         <p className="text-center text-[#fff7f4] text-xl font-bold leading-tight">
//           Let’s Go!
//         </p>
//       </button>
//     </div>
//   );
// };

const Location = ({ setStep }: any) => {
  const { locations } = useDBContext();
  const setLocations = useContext(LocationDispatchContext);
  const [error, setError] = useState(false);

  const [locationValue, setLocationValue] =
    useState<Partial<LocationClientAPI>>();

  const { setRegionId, regionsOptions } = useBuildingSelect({
    value: locationValue || locations[0],
    locations,
    onChange: (location: Partial<LocationClientAPI>) => {
      if (location) {
        setError(false);
        setLocationValue(location);
        setLocations(location);
        Cookies.set('location', JSON.stringify(location), {
          expires: 365,
        });
      } else {
        setLocations(undefined);
        setLocationValue(undefined);
        Cookies.remove('location');
      }
    },
    regionId: locationValue?.regionId || 0,
  });

  return (
    <div>
      <h1 className="text-center text-[#331103] text-[40px] md:text-[45px] font-bold leading-[45px] pb-6">
        What’s your drop off location?
      </h1>

      <div className="w-full text-xl">
        <SelectField
          options={[
            { text: 'Choose Your Drop Off', value: 0 },
            ...regionsOptions,
          ]}
          onChange={setRegionId}
        />

        {error && (
          <div className="text-[#c12c0d] text-[14px] text-center mt-5">
            Please choose your drop off!
          </div>
        )}

        <p className="text-[#66554e] text-xs font-normal leading-[18px] pt-3">
          We use this information to show you the most relevant deals and
          restaurants for your location.
        </p>
      </div>

      <button
        className="w-full h-[44px] mx-auto px-4 bg-[#f75413] rounded-[5px] shadow justify-center items-center flex mt-12"
        onClick={() => {
          if (!_.isEmpty(locationValue)) {
            setError(false);
            setStep(3);
          } else {
            setError(true);
          }
        }}
      >
        <p className="text-center text-[#fff7f4] text-xl font-bold leading-tight">
          Next
        </p>
      </button>
    </div>
  );
};

const Campus = () => {
  const router = useRouter();
  const location: any = useLocation();
  const setLocations = useContext(LocationDispatchContext);
  const { locations } = useDBContext();
  const [locationValue, setLocationValue] =
    useState<Partial<LocationClientAPI>>();

  const [error, setError] = useState(false);

  const { buildingsOptions, setLocation, setRegionId, setRegionId2 } =
    useBuildingSelect({
      value: locationValue || locations[0],
      locations,
      onChange: (location: Partial<LocationClientAPI>) => {
        if (location) {
          setError(false);
          setLocationValue(location);
          setLocations(location);
          Cookies.set('location', JSON.stringify(location), {
            expires: 365,
          });
        } else {
          setLocations(undefined);
          setLocationValue(undefined);
          Cookies.remove('location');
        }
      },
      regionId: locationValue?.regionId || 0,
    });

  useEffect(() => {
    setRegionId2({
      id: location.regionId,
      name: location.region,
    });
  }, []);

  return (
    <div>
      <h1 className="text-center text-[#331103] text-[40px] md:text-[45px] font-bold leading-[45px] pb-6">
        What’s your campus?
      </h1>

      <div className="w-full text-xl">
        <SelectField
          options={[
            { text: 'Choose Your Campus', value: 0 },
            ...buildingsOptions,
          ]}
          onChange={setLocation}
        />
        {error && (
          <div className="text-[#c12c0d] text-[14px] text-center mt-5">
            Please choose your campus!
          </div>
        )}
        <p className="text-[#66554e] text-xs font-normal leading-[18px] pt-3">
          We use this information to show you the most relevant deals and
          restaurants for your location.
        </p>
      </div>

      <button
        className="w-full h-[44px] mx-auto px-4 bg-[#f75413] rounded-[5px] shadow justify-center items-center flex mt-12"
        onClick={() => {
          console.log('locationValue', locationValue?.id);
          if (locationValue?.id) {
            setError(false);
            router.push('/deal-orgs');
          } else {
            setError(true);
          }
        }}
      >
        <p className="text-center text-[#fff7f4] text-xl font-bold leading-tight">
          Next
        </p>
      </button>
    </div>
  );
};
